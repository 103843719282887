import { useField, Field } from "react-final-form"
import classnames from "classnames"

const InquiryTypeCheckbox = ({ inquiryTypes }) => (
  <>
    {inquiryTypes.map(inquiryType => (
      <label key={inquiryType}>
        <Field type="checkbox" name="inquiry-type" value={inquiryType}>
          {({ input }) => <input {...input} />}
        </Field>
        <span>{inquiryType}</span>
      </label>
    ))}
  </>
)

export const InquiryForm = () => {
  const prefs = [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ]

  const inquiryType = [
    "ご意見・ご要望など",
    "資料請求",
    "施設見学希望等",
    "その他",
  ]

  const {
    input: nameKanjiInput,
    meta: { error: nameKanjiError, touched: namaKanjiTouched },
  } = useField("name-kanji", {
    subscription: { value: true, error: true, touched: true },
  })
  const {
    input: nameReadingInput,
    meta: { error: nameReadingError, touched: nameReadingTouched },
  } = useField("name-reading", {
    subscription: { value: true, error: true, touched: true },
  })
  const { input: zipInput } = useField("address.zip", {
    subscription: { value: true },
  })
  const { input: prefectureInput } = useField("address.prefecture", {
    subscription: { value: true },
  })
  const { input: addressInput } = useField("address.address", {
    subscription: { value: true },
  })
  const { input: buildingInput } = useField("address.building", {
    subscription: { value: true },
  })
  // TODO: 半角のみを受け付ける
  // TODO: ハイフンの入力は受け付ける 文字数制限に含めない
  const {
    input: phoneNumberInput,
    meta: { error: phoneNumberError, touched: phoneNumberTouched },
  } = useField("phone-number", {
    subscription: { value: true, error: true, touched: true },
  })
  const {
    input: emailAddressInput,
    meta: { error: emailAddressError, touched: emailAddressTouched },
  } = useField("email-address", {
    subscription: { value: true, error: true, touched: true },
  })
  const {
    input: emailAddressConfirmInput,
    meta: {
      error: emailAddressConfirmError,
      touched: emailAddressConfirmTouched,
    },
  } = useField("email-address-confirm", {
    subscription: { value: true, error: true, touched: true },
  })
  const {
    input: contentInput,
    meta: { error: contentError },
  } = useField("content", {
    subscription: { value: true, error: true },
  })

  // TODO: エラーはメッセージでなく入力枠を赤くする
  return (
    <table className="contact">
      <tbody>
        <tr id="Cnt1">
          <th className="must">お問い合わせ種別</th>
          <td className="checkbox_square">
            <InquiryTypeCheckbox inquiryTypes={inquiryType} />
          </td>
        </tr>
        <tr id="Cnt2">
          <th className="must">お名前（漢字）</th>
          <td className="name">
            <span>
              <input
                type="text"
                name="name-kanji"
                value={nameKanjiInput.value}
                onChange={nameKanjiInput.onChange}
                onBlur={nameKanjiInput.onBlur}
                placeholder="例：藤元花子"
              />
            </span>
            <span>{namaKanjiTouched && nameKanjiError}</span>
          </td>
        </tr>
        <tr id="Cnt3">
          <th className="must">お名前（フリガナ）</th>
          <td className="name">
            <span>
              <input
                type="text"
                name="name-reading"
                value={nameReadingInput.value}
                onChange={nameReadingInput.onChange}
                onBlur={nameReadingInput.onBlur}
                placeholder="例：フジモトハナコ"
              />
            </span>
            <span>{nameReadingTouched && nameReadingError}</span>
          </td>
        </tr>
        <tr id="Cnt4">
          <th>送付先ご住所</th>
          <td className="address">
            <div className="address_list">
              <span>
                <label>郵便番号</label>
                <input
                  type="tel"
                  name="address.zip"
                  value={zipInput.value}
                  onChange={zipInput.onChange}
                  className="p-postal-code"
                  placeholder="例：889-1911"
                />
              </span>
            </div>
            <div className="address_list">
              <span>
                <label>都道府県</label>
                <select
                  name="address.prefecture"
                  value={prefectureInput.value}
                  onChange={prefectureInput.onChange}
                >
                  <option value="">都道府県を選択してください</option>
                  {prefs.map(pref => (
                    <option key={pref} value={pref}>
                      {pref}
                    </option>
                  ))}
                </select>
              </span>
            </div>
            <div className="address_list">
              <span>
                <label>ご住所</label>
                <input
                  type="text"
                  name="address.address"
                  value={addressInput.value}
                  onChange={addressInput.onChange}
                  className="p-address"
                />
              </span>
            </div>
            <div className="address_list">
              <span>
                <label>建物名</label>
                <input
                  type="text"
                  name="address.building"
                  value={buildingInput.value}
                  onChange={buildingInput.onChange}
                  className="p-address"
                />
              </span>
            </div>
          </td>
        </tr>
        <tr id="Cnt5">
          <th className="must">電話番号</th>
          <td className="tel">
            <span>
              <input
                type="tel"
                name="phone-number"
                value={phoneNumberInput.value}
                onChange={phoneNumberInput.onChange}
                onBlur={phoneNumberInput.onBlur}
                placeholder="例：0986-52-6921"
              />
            </span>
            <span>{phoneNumberTouched && phoneNumberError}</span>
            <p>
              ※固定電話・携帯電話のどちらでも構いませんが、連絡の取りやすい番号をご記入ください。
            </p>
          </td>
        </tr>
        <tr id="Cnt6">
          <th className="must">メールアドレス</th>
          <td className="mail cont">
            <span>
              <input
                type="email"
                name="email-address"
                value={emailAddressInput.value}
                onChange={emailAddressInput.onChange}
                onBlur={emailAddressInput.onBlur}
                placeholder="例：fujimoto@fujimoto.or.jp"
              />
            </span>
            <span>{emailAddressTouched && emailAddressError}</span>
            <br />
            <br />
            <span>
              <input
                type="email"
                name="email-address-confirm"
                value={emailAddressConfirmInput.value}
                onChange={emailAddressConfirmInput.onChange}
                onBlur={emailAddressConfirmInput.onBlur}
                placeholder="確認のためもう一度ご入力ください"
              />
            </span>
            <span>
              {emailAddressConfirmTouched && emailAddressConfirmError}
            </span>
            <p>
              ※フリーメールでも構いませんが、@fujimoto.or.jpからのメールを受信できるようにしてください。
            </p>
          </td>
        </tr>
        <tr>
          <th>お問い合わせ内容</th>
          <td className="message">
            <span>
              <textarea
                name="content"
                value={contentInput.value}
                onChange={contentInput.onChange}
                onBlur={contentInput.onBlur}
                rows="10"
                placeholder="お問合せをいただく場合はこちらにご入力ください。"
              />
              <span>{contentError}</span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
