// TODO: i18n

export const InquiryActions = ({
  valid,
  editable,
  handleConfirm,
  handleEditable,
}) => (
  <div className="send_area">
    <p>
      入力された情報は当院の
      <a href="/privacy_policy" target="_blank">
        個人情報保護方針
      </a>
      に基づき取り扱われます。
    </p>
    {editable ? (
      <button type="button" disabled={!valid} onClick={handleConfirm}>
        入力内容を確認する
      </button>
    ) : (
      <>
        <button type="submit" disabled={!valid}>
          内容を送信する
        </button>
        <button type="button" className="fix" onClick={handleEditable}>
          修正する
        </button>
      </>
    )}
  </div>
)
