import { useCallback, useState } from "react"
import { Helmet } from "react-helmet"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"
import { Form } from "react-final-form"
import { intersection } from "lodash"

import Footer from "components/Footer"
import { PageTopAnchor } from "components/PageTopAnchor"
import { PcFooterLink } from "components/FooterLink/Pc"
import { MoFooterLink } from "components/FooterLink/Mo"
import { MoFooterWrap } from "components/FooterWrap"
import { PhoneArea } from "components/PhoneArea"
import { BannerArea } from "components/BannerArea"
import { InquiryNavi } from "components/Navis/InquiryNavi"
import { InquiryForm } from "components/Inquiry/Form"
import { InquiryConfirm } from "components/Inquiry/Confirm"
import { InquiryActions } from "components/Inquiry/Actions"
import { REGEXP_EMAIL_ADDRESS } from "utils"

const IndexPage = () => {
  const { t } = useTranslation()

  // フォームと確認画面の切り替え
  const [editable, setEditable] = useState(true)
  const handleConfirm = useCallback(() => setEditable(false), [])
  const handleEditable = useCallback(() => setEditable(true), [])

  const onSubmit = useCallback(values => {
    console.log(values) // TODO: submit
  }, [])

  // 初期値
  const initialValues = {
    "inquiry-type": [],
    "name-kanji": "",
    "name-reading": "",
    "address": {
      zip: "",
      prefecture: "",
      address: "",
      building: "",
    },
    "phone-number": "",
    "email-address": "",
    "email-address-confirm": "",
    "content": "",
  }

  /**
   * バリデーション
   */
  const validate = values => {
    // 問い合わせ種別
    const errors = {}
    if (values["inquiry-type"].length === 0) {
      errors["inquiry-type"] = "Required"
    }

    // 名前
    if (!values["name-kanji"]) {
      errors["name-kanji"] = "Required"
    }
    if ([...(values["name-kanji"] || "")].length > 50) {
      errors["name-kanji"] = "Too long"
    }
    if (!values["name-reading"]) {
      errors["name-reading"] = "Required"
    }
    if ([...(values["name-reading"] || "")].length > 50) {
      errors["name-reading"] = "Too long"
    }

    // 電話番号 数字だけで 10-13 文字
    if (!values["phone-number"]) {
      errors["phone-number"] = "Required"
    }
    const no = (values["phone-number"] || "").replace(/[^\d]+/g, "")
    if (no.length > 13) {
      errors["phone-number"] = "Too Long"
    }
    if (no.length < 10) {
      errors["phone-number"] = "Too Short"
    }

    // メールアドレス 100 文字まで
    if (!values["email-address"]) {
      errors["email-address"] = "Required"
    } else if (!REGEXP_EMAIL_ADDRESS.test(values["email-address"])) {
      errors["email-address"] = "Invalid format"
    } else if (values["email-address"] !== values["email-address-confirm"]) {
      errors["email-address"] = "Must match"
    }
    if ((values["email-address"] || "").length > 100) {
      errors["email-address"] = "Too Long"
    }

    // 特定の問い合わせ種類で、必須にする 1000 文字まで
    const requiredType = intersection(
      ["ご意見・ご要望など", "その他"],
      values["inquiry-type"],
    )
    if (requiredType.length > 0) {
      if (!values["content"]) {
        errors["content"] = "Required"
      }
      if ((values["content"] || "").length > 1000) {
        errors["content"] = "Too Long"
      }
    }
    return errors
  }

  return (
    <main>
      <Helmet
        title={
          editable
            ? t("お問い合わせ", "お問い合わせ")
            : t("お問い合わせ 内容確認", "お問い合わせ 内容確認")
        }
      />
      <div className="sub_page">
        <section className="page_header service inviewfadeInUp">
          <div className="container2">
            <h1>
              {editable
                ? t("お問い合わせ", "お問い合わせ")
                : t("お問い合わせ 内容確認", "お問い合わせ 内容確認")}
            </h1>
          </div>
        </section>

        <div className="container2">
          <section className="breadcrumb inviewfadeInUp">
            <ul>
              <li>
                <Link to="/">{t("星井眼科医院TOP", "星井眼科医院TOP")}</Link>
              </li>
              <li>
                {editable
                  ? t("お問い合わせ", "お問い合わせ")
                  : t("お問い合わせ 内容確認", "お問い合わせ 内容確認")}
              </li>
            </ul>
          </section>

          <section className="content">
            <div className="main_area inviewfadeInUp">
              <div className="department_block inviewfadeInUp">
                <h2>
                  {editable
                    ? t("お問い合わせ", "お問い合わせ")
                    : t("お問い合わせ 内容確認", "お問い合わせ 内容確認")}
                </h2>
                <div className="general_box">
                  <div className="general_part">
                    <div className="inquiry_area">
                      <p className="note">
                        <span className="red">※</span>は必須項目です。
                      </p>
                      <Form
                        onSubmit={onSubmit}
                        validate={validate}
                        initialValues={initialValues}
                        keepDirtyOnReinitialize={true}
                        subscription={{
                          valid: true,
                        }}
                      >
                        {({ handleSubmit, valid }) => (
                          <form onSubmit={handleSubmit}>
                            <>
                              {editable ? <InquiryForm /> : <InquiryConfirm />}
                              <InquiryActions
                                valid={valid}
                                editable={editable}
                                handleConfirm={handleConfirm}
                                handleEditable={handleEditable}
                              />
                            </>
                          </form>
                        )}
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <InquiryNavi />
          </section>
        </div>
      </div>

      <div className="container2">
        <section className="content">
          <PhoneArea />
          <BannerArea />
        </section>
      </div>

      <section className="content">
        <div className="footer_link inviewfadeInUp">
          <PcFooterLink />
          <MoFooterLink />
        </div>
      </section>

      <Footer />
      <PageTopAnchor />
      <MoFooterWrap />
    </main>
  )
}

export default IndexPage
