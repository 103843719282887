import { useField } from "react-final-form"

// TODO: i18n
export const InquiryConfirm = () => {
  const {
    input: { value: inquiryTypeValue },
  } = useField("inquiry-type", { subscription: { value: true } })
  const {
    input: { value: nameKanjiValue },
  } = useField("name-kanji", { subscription: { value: true } })
  const {
    input: { value: nameReadingValue },
  } = useField("name-reading", { subscription: { value: true } })
  const {
    input: { value: zipValue },
  } = useField("address.zip", { subscription: { value: true } })
  const {
    input: { value: prefectureValue },
  } = useField("address.prefecture", { subscription: { value: true } })
  const {
    input: { value: addressValue },
  } = useField("address.address", { subscription: { value: true } })
  const {
    input: { value: buildingValue },
  } = useField("address.building", { subscription: { value: true } })
  const {
    input: { value: phoneNumberValue },
  } = useField("phone-number", { subscription: { value: true } })
  const {
    input: { value: emailAddressValue },
  } = useField("email-address", { subscription: { value: true } })
  const {
    input: { value: emailAddressConfirmValue },
  } = useField("email-address-confirm", { subscription: { value: true } })
  const {
    input: { value: contentValue },
  } = useField("content", { subscription: { value: true } })

  return (
    <table className="contact">
      <tbody>
        <tr id="Cnt1">
          <th className="must">お問い合わせ種別</th>
          <td className="checkbox_square">
            {inquiryTypeValue.map(value => (
              <div key={value}>{value}</div>
            ))}
          </td>
        </tr>
        <tr id="Cnt2">
          <th className="must">お名前（漢字）</th>
          <td className="name">
            <span>{nameKanjiValue}</span>
          </td>
        </tr>
        <tr id="Cnt3">
          <th className="must">お名前（フリガナ）</th>
          <td className="name">
            <span>{nameReadingValue}</span>
          </td>
        </tr>
        <tr id="Cnt4">
          <th>送付先ご住所</th>
          <td className="address">
            <div className="address_list">
              <span>
                <label>郵便番号</label>
                {zipValue}
              </span>
            </div>
            <div className="address_list">
              <span>
                <label>都道府県</label>
                {prefectureValue}
              </span>
            </div>
            <div className="address_list">
              <span>
                <label>ご住所</label>
                {addressValue}
              </span>
            </div>
            <div className="address_list">
              <span>
                <label>建物名</label>
                {buildingValue}
              </span>
            </div>
          </td>
        </tr>
        <tr id="Cnt5">
          <th className="must">電話番号</th>
          <td className="tel">
            <span>{phoneNumberValue}</span>
          </td>
        </tr>
        <tr id="Cnt6">
          <th className="must">メールアドレス</th>
          <td className="mail cont">
            <span>{emailAddressValue}</span>
            <span style={{ visibility: "hidden" }}>
              {emailAddressConfirmValue}
            </span>
          </td>
        </tr>
        <tr>
          <th>お問い合わせ内容</th>
          <td className="message">
            <span>{contentValue}</span>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
